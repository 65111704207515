html,
body {
    width: 100%;
    margin: 0px;
    padding: 0px;
    cursor: default;
}

#container {
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#logo {
    width: 40px;
    font-family: 'Inconsolata';
    color: white;
    line-height: 80px;
    padding: 20px;
    font-weight: bold;
    font-size: 40px;
    cursor: pointer;
}

a{
    text-decoration: none !important;
    color: white;
}

a:hover {
    text-decoration: none !important;
  }

canvas {
    position: absolute !important;
}

p{
    margin: 0;
}

div{
    margin: 0;
    padding: 0;
}

#nav {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
}

#nav div{
    color: white;
    cursor: pointer;
}

.underline{
    margin-left: 25px;
    margin-right: 25px;
    border-bottom: 3px solid transparent;
}

.section h3{
    padding-left: 20px;
    padding-right: 20px;
    margin:10px;
    color: white;
    text-transform: uppercase;
}

#logo button{
    border-color: white;
    background-color: transparent;
    display: none;
    margin: 0;
    align-items: center;
}

#nav div:hover > .underline{
    border-bottom: 3px solid white;
    transition: border-color 0.3s ease, transform 0.3s ease;
    border-radius: 3px;
}

#container .icon{
    display: none;
}

#navbar {
    height: 80px;
    width: 100%;
    position: fixed;
    z-index: 100;
}

#tsparticles {
    position: relative;
    top: -80px;
    left: 0px;
    height: 80px;
    z-index: -100;
}

canvas {
    position: relative !important;
}

@media screen and (min-width: 0px) {
    #logo{
        font-size: 55px;
    }
}

@media screen and (max-width: 800px) {
    #nav{
        font-size: 3vw;
        display: none;
        padding: 10px;
        box-sizing: border-box;
    }

    #logo button{
        display: flex;
    }
    
    .section h3{
        padding-left: 10px;
        padding-right: 10px;
        margin:5px;
    }

    #container .icon {
        float: right;
        display: block;
      }

    #nav.responsive{
        display: block;
        text-align: center;
        position: absolute;
        top:80px;
        width:100%;
        background-color:darkslategray;
    }
}
