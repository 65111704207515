#projectContainer{
    background-color: 	#FAFAED;
    padding: 20px;
    margin: 0;
  }
  
  #projects{
    margin:20px;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    grid-gap: 30px;
    align-items: center;
    justify-content: center;
    grid-auto-flow: row;
    color: white;

  }
  
  #projects .project{
    border-radius: 10px;
    height: 250px;
    overflow: hidden;
    color: white;
    text-decoration: none;
    box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .title{
    background-color: darkslategrey;
    position: sticky;
    bottom:  0;
    z-index: 3;
    padding: 10px;
    height: 12%;
  }

  h2{
    margin:0;
    padding: 0;
  }

  .project:hover .overlay{
    background-color: green;
  }

  .projImgC{
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .project div img{
    width: 100%;
    height: 90%;
    background-image: url(https://cdn2.unrealengine.com/egs-thelastcampfire-hellogames-s1-2560x1440-792480083.jpg?h=1080&resize=1&w=1920);
    background-size: contain;
    background-color: black;
    display: block;
    background-position:0% 40%;
    position: relative;
    transition: transform 1.5s;
    z-index: 2;
  }

  .project:hover .overlay{
    position: absolute;
    top:0;
    width:100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    z-index: 2;
  }

  .project:hover .title{
    text-decoration: underline;
  }

  .project:hover img{
    transform: scale(1.1);
  }

button{
  padding: 10px;
  border-radius: 4px;
  background-color: transparent;
  border: 2px solid rgba(0,116,217,0.9);
  background-color: rgba(0,116,217,1);
  color:white;
  cursor: pointer;
  font-weight: bold;
  
}

button:hover {
  background: rgba(0,116,217,0.9);
  color:white;
}

@media screen and (max-width: 600px) {
  #projects{
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media screen and (min-width: 600px) and (max-width: 1024px){
  #projects{
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1800px){
  #projects{
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    padding-left: 5%;
    padding-right: 5%;
  }
}

#captcha{
  background-image: url("../../Pages/Website/CAPTCHA/CAPTCHA3.png");
  background-size: contain;
  background-repeat: no-repeat;
}

#PDFVoiceTranslate{
  background-image: url("../../Pages/Website/PDFVoiceTranslate/voice2.png");
  background-size: contain;
  background-repeat: no-repeat;
}

#catjump{
  background-image: url("../../Pages/Website/Cat jump/cat1.png");
  background-size: cover;
  background-repeat: no-repeat;
}

#showLoader{
  background-image: url("../../Pages/Website/ShowLoader/loader1.png");
  background-size: cover;
  background-repeat: no-repeat;
}

#ragdoll{
  background-image: url("../../Pages/Website/Revolving Rabbits/rabbit.png");
  background-size: cover;
  background-repeat: no-repeat;
}

