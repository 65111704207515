html, body {
  width:100%;
  margin: 0px;
  padding: 0px;
  cursor: default;
}

div{
  margin:0;
  padding: 0;
}

.App{
  text-align: center;
  height: 100%; 
  font-family: 'Inconsolata', monospace;
}