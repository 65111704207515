#headlineContainer{
    display: grid;
    align-items: center;
    width:100%;
    height:100%;
    grid-template-areas:
    ". . .   .   pic pic .   .   . ."
    ". . .   .   pic pic   .   .   . ."
    ". . .   t   t   t   t   .   . ."
    ". . .   des des des des . . ."
    ". . .   .   d   d   .   .   . ."
  ;  
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: repeat(10, 1fr);
    margin:0px;
    padding: 0px;
    background-color: #FAFAED;
  }

  #avatar{
    width: 100%;
    height:100%;
    grid-area: 'pic';
  }

  #name{
    font-size:7em;
    font-family: 'Inconsolata', monospace;
    margin: 0;
  }

#description{
    align-self: flex-start;
    font-weight: 500 ;
  }

  #redirect{
    cursor: pointer;
    color: black;
    display: block;
    text-decoration: none;
    animation-name: example;
    animation-timing-function: ease-in-out;
    animation-duration: 2s;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
  }


@keyframes example {
  0%   {transform: translate(0px,0px);}
  100%  {transform: translate(0px,-25px);}
}


@media screen and (max-width: 1200px) and (min-width: 500px){
  #headlineContainer{
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas:
    ". . . . ."
    ". pic pic pic ."
    ". pic pic pic ."
    ". t t t ."
    ". des des des ."
    ". . d . ."
    ;  
  }

  #name{
    font-size: 14vw;
  }
}

@media screen and (max-width: 500px) {
  #headlineContainer{
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-template-areas:
    ". . . . ."
    ". pic pic pic ."
    ". pic pic pic ."
    ". t t t ."
    ". des des des ."
    ". . d . ."
    ;  
  }

  #name{
    font-size: 14vw;
    display: flex;
    justify-content: center;
  }

  #down{
    display: flex;
    align-self: flex-start;
    justify-content: center;
  }
}


