
#blogContainer{
    background-color:#FAFAED;
    padding: 20px;
  }
  
  #blogs{
    margin:20px;
    padding-left: 25%;
    padding-right: 25%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    grid-gap: 15px;
    align-items: center;
    justify-content: center;
  }
  
  .blog{
    background-color: white ;
    color: black;
    text-decoration: none;
    border-radius: 10px;
    height: 300px;
    overflow: hidden;
    box-shadow:  0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

.blog:hover .overlay{
  position: absolute;
  bottom: 0px;
  left: 0px;
  width:100%;
  height:100%;
  background-color: rgba(255, 255, 255, 0.15);
}

.blogInfo{
  height: 40%;
  padding: 10px;
}

.blogTitle{
  height: 70%;
  padding: 0;
  margin: 0;
}

  .blogExtra{
    height: 30%;
    color: gray;
    display: flex;
    justify-content: space-between;
  }

  .blog:hover .blogTitle{
    text-decoration: underline;
  }

.blogImg{
  max-height: 100%;
  object-fit: cover;
}

.imgContainer{
  height: 60%;
  position: relative;
}

#blogContainer > h1{
  display: inline-block;
  position: relative;
  
}

@media screen and (min-width: 0px) and (max-width: 500px){
  #blogs{
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    padding-left: 10%;
    padding-right: 10%;
  }

}

@media screen and (min-width: 501px) and (max-width: 900px){
  #blogs{
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media screen and (min-width: 900px){
  #blogs{
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
  }
}
